import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import {
  Box,
  Paper,
  Typography,
  Link,
  Grid,
  CircularProgress,
  Chip,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { PrimaryButton } from "../../assets/theme/overrides/button";
import FormField from "../../assets/theme/overrides/FormField";

const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};
const Item = styled(Paper)(({ theme }) => ({
  // borderRadius: "20px",
  padding: "30px 55px",
  width: "100%",
  maxWidth: "400px",
  textAlign: "center",
  // backgroundColor: "#F9F9FA",
  // borderRadius: "4px",
  // boxShadow: "none",
  // "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.3), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  [theme.breakpoints.down("sm")]: {
    width: "320px",
    padding: "24px 12px",
    // boxShadow: "none",
    // backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
}));

export default function Signin() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = await Auth.signIn(values.email, values.password);
      if (data) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("signin error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Item elevation={4}>
          <Box>
            <Badge color="secondary" sx={{ mt: 5, mb: 7 }}>
              <Typography
                sx={{ fontSize: { xs: "35px", md: "35px" }, color: "#5f5e5e" }}
              >
                pra
                <span style={{ color: "#02a1ff" }}>x</span>
                is/ai
              </Typography>
              {/* <Chip
                label="BETA"
                variant="outlined"
                color="primary"
                size="small"
                sx={{
                  color: "#0557FA",
                  fontSize: { xs: "20px", md: "28px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                  border: "none",
                }}
              /> */}
            </Badge>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <Box
                  sx={{
                    width: "300px",
                  }}
                >
                  <FormField name="email" label="Email" type="email" />
                  <FormField name="password" label="Password" type="password" />
                </Box>

                <Typography
                  onClick={() => navigate("/forgot-password")}
                  sx={{
                    cursor: "pointer",
                    color: "#0376ba",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Forgot password?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "30px 0",
                  }}
                >
                  <PrimaryButton
                    variant="contained"
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      "Sign In"
                    )}
                  </PrimaryButton>
                </Box>
              </Form>
            </Formik>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
              <Typography sx={{ fontWeight: "bold", color: "#5f5e5e" }}>
                Don't have an account?
              </Typography>

              <Typography
                onClick={() => navigate("/signup")}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  ml: 1,
                  color: "#0376ba",
                  fontWeight: "bold",
                }}
              >
                Register here.
              </Typography>
            </Box>
          </Box>
        </Item>
      </Grid>
    </>
  );
}
