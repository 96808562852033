import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const DefaultButtonStyles = styled(Button)(({ theme }) => ({
  borderRadius: "0px",
  padding: "14px 32px",
  textTransform: "capitalize",
  fontSize: "18px",
  fontWeight:500,
  lineHeight: "18px",
}));

export const PrimaryButton = styled(DefaultButtonStyles)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export const SecondaryButton = styled(DefaultButtonStyles)(({ theme }) => ({
  backgroundColor: theme.palette.grey.gray_100,
  color: "#000",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.grey.gray_200_,
    boxShadow: "1",
  },
}));
