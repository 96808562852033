import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormControlLabel,
  Slider,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  setTypeOfAg,
  setIsNewChatModel,
  setIsRFormate,
  setSelectedAIModel,
  setTemperature,
  setVectorTypeDB,
  setPipelineTypeDoc,
  setFlowPipeline,
} from "../redux/reducer";
import StartNewChat from "./Main/StartNewChat";
import IOSSwitch from "./IOSSwitch";
import MufgLOGO from "../assets/Images/mufg.png";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    backgroundColor: "rgba(236,236,241,1)",
    borderRadius: 10,
    padding: 5,
  },
  "& .MuiToggleButtonGroup-grouped": {
    borderRadius: 10,
    borderColor: "rgba(207,207,214,0.4)",
    textTransform: "capitalize !important",
  },
  "& .Mui-selected": {
    backgroundColor: "white !important",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    marginBottom: 10,
  },
}));

function getEmailDomain(email) {
  // Split the email address at the "@" symbol
  const parts = email.split("@");

  return parts[1];
}

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [agentList, setAgentList] = useState([
    { label: "Standard", value: "default" },
  ]);

  const isTyping = useSelector((state) => state.isTyping);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const chatHistory = useSelector((state) => state.chatHistory);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const flowPipeline = useSelector((state) => state.flowPipeline);
  const myFileList = useSelector((state) => state.myFileList);

  const typeOfAg = useSelector((state) => state.typeOfAg);
  const user = useSelector((state) => state.user);

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate("/signin");
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  const handleClickOpen = () => {
    dispatch(setIsNewChatModel(true));
  };

  useEffect(() => {
    if (user?.email) {
      const domain = getEmailDomain(user?.email);
      const meetAg = { value: "meetingAssistant", label: "Meeting Assistant" };
      const UXAg = { value: "UX", label: "UX Assistant" };
      const MUFGAg = { value: "MUFG SuperChat", label: "MUFG SuperChat" };
      const PipGAg = { value: "PiP SuperChat", label: "PiP SuperChat" };

      switch (domain) {
        case "hellopraxis.com":
          setAgentList([...agentList, meetAg, UXAg, MUFGAg, PipGAg]);
          break;
        case "pip.global":
          setAgentList([...agentList, meetAg, UXAg, PipGAg]);
          break;
        case "linkgroup.com":
          setAgentList([...agentList, MUFGAg]);
          break;

        default:
          setAgentList([...agentList, meetAg, UXAg]);
          break;
      }
    }
  }, [user]);

  return (
    <Box
      sx={{
        padding: "15px",
        backgroundColor: "rgba(255,255,255,0.9)",
        position: "absolute",
        width: "-webkit-fill-available",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate("/landing", { state: { tabValue: 0 } })}
        >
          {typeOfAg === "MUFG SuperChat" ? (
            <div style={{ width: "100px" }}>
              <img src={MufgLOGO} alt="MufgLOGO" />
            </div>
          ) : (
            <Typography sx={{ fontSize: "24px !important", color: "#1235f5" }}>
              praxis/ai
            </Typography>
          )}
        </Box>
        {typeOfAg === "UX" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              border: "2px solid #193bf5",
              padding: { md: "6px 12px", xs: "4px 10px" },
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.grey.gray_500,
                fontSize: { md: "14px", xs: "12px" },
                fontWeight: 700,
              }}
            >
              UX Assistant
            </Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", gap: 2 }}>
          {!isTyping && chatHistory.length !== 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="text"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: { md: "14px", xs: "10px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                  minWidth: { xs: "0px" },
                }}
                endIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                <Hidden only={["sm", "xs"]}>New Chat</Hidden>
              </Button>
            </Box>
          )}

          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "50px",
            }}
          >
            <IconButton onClick={() => setDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <Drawer
          open={drawer}
          onClose={() => setDrawer(false)}
          role="presentation"
          anchor="right"
        >
          <Box
            p="6px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid rgba(0, 0, 0, 0.12)"
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                paddingLeft: "16px",
              }}
            >
              Menu
            </Typography>
            <IconButton onClick={() => setDrawer(false)}>
              <CloseSharpIcon />
            </IconButton>
          </Box>
          <Box className={classes.root}>
            <Box sx={{ paddingLeft: "16px" }}>
              <Button
                variant="text"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
                endIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                New Chat
              </Button>
              <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <Typography fontWeight="500">AI model:</Typography>

                <Box sx={{ ml: 4 }}>
                  <Select
                    value={selectedAIModel}
                    fullWidth
                    onChange={(e) =>
                      dispatch(setSelectedAIModel(e.target.value))
                    }
                  >
                    <MenuItem
                      value="gpt-3.5-turbo-0125"
                      disabled={pipelineTypeDoc === "openaiFile"}
                    >
                      GPT-3.5
                    </MenuItem>
                    <MenuItem value="gpt-4-turbo">GPT-4 Turbo</MenuItem>
                    <MenuItem value="gpt-4o">GPT-4o</MenuItem>
                    <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                <Typography fontWeight="500">Agent:</Typography>

                <Box sx={{ ml: 6 }}>
                  <Select
                    value={typeOfAg}
                    onChange={(e) => dispatch(setTypeOfAg(e.target.value))}
                    fullWidth
                  >
                    {agentList.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Accordion>
                  <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    <Typography>Admin</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                      <Typography fontWeight="500">
                        Doc Pipeline Type:
                      </Typography>

                      <Box sx={{ ml: 6 }}>
                        <Select
                          value={pipelineTypeDoc}
                          onChange={(e) => {
                            if (myFileList >= 1) {
                              handleClickOpen();
                            }
                            if (e.target.value === "codeInterpreter") {
                              dispatch(setSelectedAIModel("gpt-4-turbo"));
                            }
                            dispatch(setPipelineTypeDoc(e.target.value));
                          }}
                          fullWidth
                        >
                          <MenuItem value={"openaiFile"}>
                            OpenAI file assistant
                          </MenuItem>
                          <MenuItem value={"codeInterpreter"}>
                            OpenAI code interpreter
                          </MenuItem>
                          <MenuItem value={"langchain"}>
                            Langchain vector chain
                          </MenuItem>
                        </Select>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                      <Typography fontWeight="500">Work Flow Type:</Typography>
                      <Box sx={{ ml: 6 }}>
                        <Select
                          value={flowPipeline}
                          onChange={(e) => {
                            dispatch(setFlowPipeline(e.target.value));
                          }}
                          fullWidth
                        >
                          <MenuItem value={"langGraph"}>langGraph</MenuItem>
                          <MenuItem value={"funchain"}>func-Chain</MenuItem>
                        </Select>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ ml: 2 }}
                            checked={isRFormate}
                            onChange={(e) =>
                              dispatch(setIsRFormate(e.target.checked))
                            }
                          />
                        }
                        label="Report format"
                        labelPlacement="start"
                        sx={{ ml: 0 }}
                      />
                    </Box>

                    {pipelineTypeDoc === "langchain" && (
                      <Box
                        sx={{ mt: 4, display: "flex", alignItems: "center" }}
                      >
                        <Typography fontWeight="500" mr={1}>
                          Select vector store:-
                        </Typography>
                        <StyledToggleButtonGroup
                          color="primary"
                          value={vectorTypeDB}
                          exclusive
                          onChange={(e) =>
                            dispatch(setVectorTypeDB(e.target.value))
                          }
                        >
                          <ToggleButton value="FAISS">
                            FAISS (faster)
                          </ToggleButton>
                          <ToggleButton value="chromadb">
                            Chroma (longer)
                          </ToggleButton>
                          {/* { (
                          <ToggleButton value="elasticSearch">
                            Elastic-Search
                          </ToggleButton>
                        )} */}
                        </StyledToggleButtonGroup>
                      </Box>
                    )}

                    <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                      <Typography fontWeight="500" mr={1}>
                        Temperature:-
                      </Typography>
                      <Slider
                        value={temperature}
                        step={0.1}
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                        onChange={(e, newvalue) =>
                          dispatch(setTemperature(newvalue))
                        }
                        sx={{ mr: 3 }}
                      />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                      <Button
                        variant="outlined"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {
                          setDrawer(false);
                          navigate("/Categorization");
                        }}
                      >
                        Categorisation Agent
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleLogout();
                }}
              >
                Log out
                <LogoutIcon sx={{ ml: 1 }} />
              </Typography>
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box>
        <StartNewChat fnCall={() => setDrawer(false)} />
      </Box>
    </Box>
  );
};

export default Header;
