import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, Button, LinearProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setIsNewChatModel, setMyFileList } from "../../redux/reducer";
import FileList from "./FileList";
import { Storage } from "aws-amplify";
import axios from "axios";
import StartNewChat from "./StartNewChat";

const audioFilesTypes = [
  "audio/wav",
  "audio/mpeg",
  "audio/ogg",
  "audio/x-m4a",
  "video/mp4",
  "video/mpeg",
  "video/webm",
];

function FileUpload({
  setMyFiles,
  setValues,
  myFiles,
  handleChange,
  setTabValue,
  values,
  chatHistoryLength,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...acceptedFiles]);
      setValues({ ...values, remote_url: "" });
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xlsx"],
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "audio/*": [
        ".mp3",
        ".mp4",
        ".mpeg",
        ".mpga",
        ".m4a",
        ".wav",
        ".ogg",
        ".webm",
      ],
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (myFiles.length > 0) {
      dispatch(setMyFileList(1));
    } else {
      dispatch(setMyFileList(0));
    }
  }, [myFiles]);

  // const handleFileUpload = () => {
  //   if (myFiles?.length === 0 && !values.remote_url) {
  //     return toast.error("Please choose PDF file or provide remote url");
  //   } else {
  //     toast.success("File or remote url upload successfully");
  //     handleChange(null, 0);
  //   }
  // };

  const handleOpen = () => {
    if (chatHistoryLength) {
      dispatch(setIsNewChatModel(true));
    } else {
      open();
    }
  };

  const convertToPng = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File(
              [blob],
              `${file.name.substring(0, file.name.lastIndexOf("."))}.png`,
              {
                type: "image/png",
              }
            );
            resolve(convertedFile);
          }, "image/png");
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadFile = async (acceptedFiles) => {
    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat/clearSession`,
        { userId: user.sub }
      );

      await Promise.all(
        acceptedFiles.map(async (file, i) => {
          if (file.type === "image/jpg" || file.type === "image/jpeg") {
            acceptedFiles[i] = await convertToPng(file);
          }
        })
      );

      await Promise.all(
        acceptedFiles.map(
          async (file) =>
            await Storage.put(`${user.sub}/${file.name}`, file, {
              contentType: file.type,
            })
        )
      );
      const ingoreFileopenAI = acceptedFiles.filter((file) =>
        [
          "image/png",
          "image/jpeg",
          "text/csv",
          "application/vnd.ms-excel",
        ].includes(file.type)
      ).length;

      if (!ingoreFileopenAI && pipelineTypeDoc === "openaiFile") {
        const formData = new FormData();
        formData.append("userId", user.sub);
        acceptedFiles.map((file) => formData.append("file", file));
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/uploadMultiple`,
          formData
        );
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  const speechToTextSubmit = async (file) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.append("file", file);
      formData.append("email", user.email);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/speechToText`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(res?.data?.response);
      setIsLoading(false);
      setMyFiles([]);
    } catch (error) {
      setIsLoading(false);
      console.error("Error catch message:", error);
      toast.error(
        error?.response?.data?.error || error.message || "Something went wrong"
      );
      setMyFiles([]);
    }
  };

  const isAudioFile = audioFilesTypes.includes(myFiles[0]?.type);

  const handleSubmit = async () => {
    if (isAudioFile) {
      await speechToTextSubmit(myFiles[0]);
    } else {
      await uploadFile(myFiles);
    }
    setTabValue(0);
  };

  return (
    <>
      <Box
        sx={{
          p: { xs: 3, sx: 4, md: 5 },
          mt: 10,
          maxWidth: {
            xs: "450px",
            sm: "540px",
            md: "800px",
          },
          margin: "0 auto",
        }}
      >
        <Box>
          <Box display="flex" alignItems="center" mb={5} mt={10}>
            {/* <IconButton onClick={() => setTabValue(0)}>
              <ArrowBackIcon />
            </IconButton> */}
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 400,
                textAlign: "center",
                ml: "auto",
                mr: "auto",
              }}
            >
              Sources
            </Typography>
          </Box>
          <Box
            {...getRootProps({ className: "dropzone" })}
            sx={{
              "&:hover": {
                border: "1px solid rgba(0, 0, 0, 0.50)",
              },
              padding: { xs: "10px 0", sm: "15px 0", md: "20px 0" },
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={handleOpen}
          >
            <input {...getInputProps()} />
            <Icon icon="tabler:upload" />

            <Typography mt={1} whiteSpace="pre-line">
              {isLoading ? (
                isAudioFile ? (
                  `Transcribing.. \n If you upload an audio file then we'll transcribe it and send the transcription to your email.`
                ) : (
                  `Uploading...`
                )
              ) : (
                <>
                  Drag and drop or&nbsp;
                  <span
                    style={{
                      color: "rgba(5, 87, 250, 1)",
                      textDecorationLine: "underline",
                    }}
                  >
                    Choose file
                  </span>
                  &nbsp; to upload
                  <br />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                    component="span"
                  >
                    PDF, CSV, Excel, Images, Text, Docs audio only supported
                  </Typography>
                </>
              )}
            </Typography>
          </Box>
          {isLoading && <LinearProgress />}
          <aside>
            <ul
              style={{
                paddingLeft: "0px",
                overflow: "auto",
                maxHeight: "140px",
              }}
            >
              {myFiles.map((file) => (
                <FileList
                  file={file}
                  setMyFilesState={async (file) => {
                    if (myFiles?.length >= 2)
                      await Storage.remove(`${user.sub}/${file.name}`);
                    setMyFiles(
                      myFiles.filter((item) => item.name !== file.name)
                    );
                  }}
                />
              ))}
            </ul>
          </aside>
          {myFiles.length > 0 && (
            <Box sx={{ textAlign: "end" }}>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                {isAudioFile ? "Upload" : "Done"}
              </Button>
            </Box>
          )}
        </Box>
        {/* <Divider
          sx={{
            marginTop: 4,
          }}
        >
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>OR</Typography>
        </Divider>
        <Box
          sx={{
            margin: "0 auto",
            marginBottom: "16px",
            marginTop: "16px",
          }}
        >
          <FormLabel
            sx={{
              display: "flex",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Import PDF from URL
          </FormLabel>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Add file URL"
              onBlur={() => values?.remote_url && setMyFiles([])}
              value={values?.remote_url}
              onChange={(e) =>
                setValues({ ...values, remote_url: e.target.value })
              }
              sx={{
                marginTop: "8px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px",
                },
              }}
              size="small"
            />
            <Button
              sx={{
                "&:hover": {
                  backgroundColor:
                    values?.remote_url.length > 0
                      ? "rgba(0,0,0,0.6)"
                      : "rgba(5, 87, 250, 0.1)",
                },
                p: "7px 14px",
                ml: 2,
                marginTop: "8px",
                color:
                  values?.remote_url.length > 0
                    ? "#0557FA"
                    : "rgba(0, 0, 0, 0.54)",
                textTransform: "capitalize",
                border:
                  values?.remote_url.length > 0
                    ? "1px solid rgba(5, 87, 250, 0.5)"
                    : "1px solid rgba(0, 0, 0, 0.2)",
                fontWeight: 500,
                borderRadius: "4px",
              }}
              onClick={handleFileUpload}
            >
              Import
            </Button>
          </Box>
        </Box> */}
        <StartNewChat fnCall={() => open()} />
      </Box>
    </>
  );
}

export default FileUpload;
