import React from "react";
import { Typography, TextField } from "@mui/material";
import { Field } from "formik";

const FormField = ({ name, label, type, disabled = false }) => (
  <Field
    name={name}
    render={({ field, form }) => (
      <>
        <TextField
          id={name}
          name={name}
          variant="outlined"
          fullWidth
          size="small"
          placeholder={label}
          type={type}
          disabled={disabled}
          {...field}
          inputProps={{
            sx: {
              padding: "15px 15px",
            },
          }}
          sx={{
            marginTop: "20px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            },
            "& .MuiOutlinedInput-input:focus": {
              border: "3px solid rgba(5, 87, 250, 1)",
            },
          }}
        />
        {form.errors[name] && form.touched[name] && (
          <Typography variant="body2" color="error" sx={{ textAlign: "left" }}>
            {form.errors[name]}
          </Typography>
        )}
      </>
    )}
  />
);
export default FormField;
