import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Paper,
  Avatar,
  Skeleton,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Send from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import {
  setChatHistory,
  setIsClearChatData,
  setIsTypingFN,
} from "../redux/reducer";
// import { SecondaryButton } from "../assets/theme/overrides/button";
import TypingText from "./TypingText";
import PromptHolder from "../Components/Main/PromptHolder";
import FileUpload from "../Components/Main/FileUpload";

const ChatBox = styled(Box)(() => ({
  display: "flex",
  // flexDirection: "column-reverse", // automatically scroll to bottom
  flexDirection: "column",
  overflowY: "auto",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

let controller;

const Landing = () => {
  const chatRef = useRef(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const [values, setValues] = useState({ query: "", remote_url: "" });
  const chatHistory = useSelector((state) => state.chatHistory);

  const [loading, setLoading] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [isTyping, setIsTyping] = useState(true);
  const isClearChatData = useSelector((state) => state.isClearChatData);
  const [colorFocus, setColorFocus] = useState(false);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);
  const user = useSelector((state) => state.user);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const flowPipeline = useSelector((state) => state.flowPipeline);
  const [showBottomBtn, setShowBottomBtn] = useState(false);

  // const isKeyboardOpen = useDetectKeyboardOpen();
  useEffect(() => {
    if (user?.sub)
      axios.post(`${process.env.REACT_APP_API_URL}/api/chat/clearSession`, {
        userId: user.sub,
      });

    chatRef.current.scrollTop = -chatRef.current.scrollHeight;
  }, [user]);

  useEffect(() => {
    if (state?.tabValue !== undefined) setTabValue(state?.tabValue);
  }, [state]);

  useEffect(() => {
    if (isClearChatData) {
      dispatch(setChatHistory([]));
      setMyFiles([]);
      setValues({ query: "", remote_url: "" });
      setLoading(false);
      dispatch(setIsClearChatData(false));
      setShowBottomBtn(false);
    }
  }, [isClearChatData]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        (chatRef.current.scrollHeight,
        chatRef.current.offsetHeight,
        chatRef.current.scrollTop ===
          chatRef.current.scrollHeight - chatRef.current.offsetHeight)
      ) {
        setShowBottomBtn(false);
      } else {
        setShowBottomBtn(true);
      }
    };
    const div = chatRef.current;
    if (chatHistory?.length > 0) {
      div?.addEventListener("scroll", handleScroll);
    }

    return () => div?.removeEventListener("scroll", handleScroll);
  }, [chatHistory]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    try {
      setLoading(true);

      if (tabValue === 0 && !values.query) {
        setLoading(false);
        return toast.error("Please add prompts!");
      }
      const newMessage = {
        id: Date.now(),
        linkId: Date.now(),
        text: values.query,
        sender: "user",
      };
      dispatch(setChatHistory([...chatHistory, newMessage]));
      setValues({ ...values, query: "" });

      const formData = new FormData();

      let query = values.query.trim();

      formData.append("query", query);
      formData.append("AImodel", selectedAIModel);
      formData.append("vectorTypeDB", vectorTypeDB);
      formData.append("userId", user.sub);
      formData.append("email", user.email);
      formData.append("temperature", temperature);
      formData.append("isRFormate", isRFormate);
      formData.append(
        "typeOfAg",
        typeOfAg === "PiP SuperChat" ? "MUFG SuperChat" : typeOfAg
      );
      formData.append("pipelineTypeDoc", pipelineTypeDoc);
      formData.append("flowPipeline", flowPipeline);
      formData.append("isFile", myFiles.length > 0);

      // let isMultiDoc = myFiles.length >= 2;
      // if (myFiles.length > 0)
      //   !isMultiDoc && formData.append("file", myFiles[0]);

      if (values.remote_url) formData.append("remote_url", values.remote_url);

      if (chatHistory.length > 0) {
        const conversation = chatHistory
          .slice(-8)
          .map(
            (item, i) =>
              item.sender === "user" &&
              chatHistory[i + 1]?.text && {
                question: item.text,
                answer: chatHistory[i + 1]?.text || "",
              }
          )
          .filter((item) => item);
        formData.append("conversation", JSON.stringify(conversation));
      }

      controller = new AbortController();
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal: controller.signal,
        }
      );
      const newMessage1 = {
        id: Date.now(),
        linkId: newMessage.linkId,
        text: res?.data?.response?.replaceAll("&", "&amp;"),
        sender: "bot",
      };
      dispatch(setChatHistory([...chatHistory, newMessage, newMessage1]));
      setLoading(false);
      setIsTyping(true);
      setShowBottomBtn(true);
    } catch (error) {
      setLoading(false);
      console.error("Error catch message:", error);
      toast.error(
        error?.response?.data?.error || error.message || "Something went wrong"
      );
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    dispatch(setChatHistory(chatHistory.map((item) => ({ ...item }))));
  };

  const handleStop = () => {
    setIsTyping(false);
    controller.abort();
    setLoading(false);
  };

  return (
    <Box
      sx={{
        mb: { xs: 0, sm: 4, md: 5 },
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Paper
            elevation={4}
            sx={{
              background: "#FDFDFD",
              border: "none",
              boxShadow: "none",
            }}
          >
            <TabPanel value={tabValue} index={0}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: { xs: "98svh", sm: "95svh", md: "98vh" },
                  position: "relative",
                }}
              >
                <ChatBox
                  sx={{
                    height: {
                      xs: "85vh",
                      sm: "88vh",
                      md: "88vh",
                    },
                    justifyContent:
                      myFiles.length === 0 && chatHistory?.length === 0
                        ? "center"
                        : "flex-start",
                  }}
                  ref={chatRef}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      paddingTop: "70px",
                    }}
                  >
                    {myFiles.length > 0 ? (
                      <Box
                        sx={{
                          p: { xs: "0px 0px", md: "0px 24px" },
                          backgroundColor: (theme) =>
                            theme.palette.grey.gray_100,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            gap: { xs: "10px", sx: "15px", md: "20px" },
                            py: { xs: 2, sx: 2, md: 1.5 },
                            px: { xs: 2, sx: 3, md: 1.5 },
                            borderRadius: "8px",
                            maxWidth: {
                              xs: "450px",
                              sm: "540px",
                              md: "800px",
                            },
                            margin: "0 auto",
                          }}
                        >
                          <Avatar
                            alt="John Doe"
                            sx={{
                              width: { xs: "25px", md: "25px" },
                              height: { xs: "25px", md: "25px" },
                              backgroundColor: "rgba(0, 0, 0, 0.05)",
                              borderRadius: "4px",
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            <PersonOutlineOutlinedIcon />
                          </Avatar>

                          <Box display="flex" alignItems="center">
                            <InsertDriveFileIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "white",
                                backgroundColor: "#8E8E9F",
                                borderRadius: "4px",
                                padding: "3px",
                              }}
                            />
                            <Typography ml={1} variant="body1">
                              {myFiles
                                .map((item) => item?.path || item?.name)
                                .join(" , ")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      chatHistory?.length === 0 && <PromptHolder />
                    )}
                    {chatHistory.map((message, index) => (
                      <Box sx={{ p: { xs: "0px 0px", md: "0px 0px" } }}>
                        {message.sender === "user" ? (
                          <Box
                            key={index}
                            sx={{
                              // display: "flex",
                              alignItems: "start",
                              gap: { xs: "10px", sx: "15px", md: "20px" },
                              py: { xs: 2, sx: 2, md: 1.5 },
                              px: { xs: 2, sx: 3, md: 1.5 },
                              borderRadius: "8px",
                              maxWidth: {
                                xs: "450px",
                                sm: "540px",
                                md: "800px",
                              },
                              margin: "0 auto",
                              // backgroundColor: (theme) => theme.palette.grey.gray_100,
                            }}
                          >
                            <Avatar
                              alt="John Doe"
                              sx={{
                                width: { xs: "25px", md: "25px" },
                                height: { xs: "25px", md: "25px" },
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                                borderRadius: "4px",
                                color: "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              <PersonOutlineOutlinedIcon />
                            </Avatar>
                            <Typography
                              mt={1}
                              variant="body1"
                              style={{
                                whiteSpace: "pre-line",
                              }}
                              sx={{
                                color: "#343541",
                              }}
                            >
                              {message?.text}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            key={index}
                            sx={{
                              // display: "flex",
                              alignItems: "start",
                              py: { xs: 1, sx: 2, md: 1.5 },
                              px: { xs: 1, sx: 3, md: 1.5 },
                              borderRight: 0,
                              borderLeft: 0,
                              backgroundColor: (theme) =>
                                theme.palette.grey.gray_100,
                            }}
                          >
                            <Box
                              sx={{
                                maxWidth: {
                                  xs: "450px",
                                  sm: "540px",
                                  md: "800px",
                                },
                                margin: "0 auto",
                              }}
                            >
                              <Avatar
                                alt="Ai"
                                sx={{
                                  width: { xs: "25px", md: "25px" },
                                  height: { xs: "25px", md: "25px" },
                                  color: "rgba(28, 27, 31, 1)",
                                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                                  borderRadius: "4px",
                                }}
                              >
                                <Icon icon="material-symbols:robot-2-outline-rounded" />
                              </Avatar>
                            </Box>

                            <Box
                              sx={{
                                maxWidth: {
                                  xs: "450px",
                                  sm: "540px",
                                  md: "800px",
                                },
                                margin: "0 auto",
                                color: "#343541",
                                fontFamily:
                                  "Roboto, Helvetica, Arial, sans-serif",
                                lineHeight: 1.5,
                                letterSpacing: "0.00938em",
                              }}
                            >
                              <Box className="markdown-body">
                                <TypingText
                                  markdownContent={message?.text}
                                  isTyping={isTyping}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                    {loading === true && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          py: { xs: 2, sx: 2, md: 3 },
                          px: { xs: 2, sx: 3, md: 4 },
                          borderRight: 0,
                          borderLeft: 0,
                          width: "90%",
                          maxWidth: { xs: "450px", sm: "540px", md: "800px" },
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={35} height={35} />
                        <Skeleton
                          variant="rounded"
                          width="50%"
                          height={28}
                        />{" "}
                        {/* Set width to "100%" */}
                      </Box>
                    )}

                    {showBottomBtn && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 100,
                          left: "50%",
                          cursor: "pointer",
                          zIndex: 20,
                        }}
                      >
                        <ArrowDownwardIcon
                          style={{
                            color: "black",
                            fontSize: "22px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "4px",
                          }}
                          onClick={() => {
                            chatRef.current.scrollTo({
                              top: chatRef.current.scrollHeight,
                              behavior: "smooth",
                            });
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </ChatBox>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    maxWidth: { xs: "450px", sm: "540px", md: "800px" },
                    margin: "0 auto",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    multiline
                    minRows={1}
                    maxRows={3}
                    fullWidth
                    value={values?.query}
                    onChange={(e) => {
                      setValues({ ...values, query: e.target.value });
                      dispatch(setIsTypingFN(true));
                    }}
                    onKeyDown={handleKeyPress}
                    onBlur={() => {
                      dispatch(setIsTypingFN(false));
                      setColorFocus(false);
                    }}
                    onFocus={() => setColorFocus(true)}
                    placeholder="Type your message..."
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove the border
                      },
                      "& .MuiOutlinedInput-input": {
                        border: "1px solid rgba(0, 0, 0, 0.03)",
                        "-webkit-appearance": "none",
                        boxShadow:
                          // "0px 0.8263900876045227px 1.3282830715179443px 0px rgba(44, 44, 44, 0.3), 0px 2.049459218978882px 3.192049026489258px 0px rgba(44, 44, 44, 0.3), 0px 3.965571165084839px 6.010344982147217px 0px rgba(44, 44, 44, 0.01), 0px 7.231224060058594px 10.721427917480469px 0px rgba(44, 44, 44, 0.02), 0px 13.730557441711426px 20.053253173828125px 0px rgba(44, 44, 44, 0.03), 0px 33px 48px 0px rgba(44, 44, 44, 0.05)",
                          "0 0 transparent,0 0 transparent,0 0 15px rgba(0,0,0,.1)",
                        borderRadius: "8px",
                        padding: "9px 12px",
                      },

                      "& .MuiOutlinedInput-input:focus": {
                        border:
                          values.query.length > 0
                            ? "1px solid rgba(5, 87, 250, 1)"
                            : "",
                      },
                      "& .MuiOutlinedInput-input:hover": {
                        border: "1px solid rgba(5, 87, 250, 1)",
                      },
                      "& .MuiOutlinedInput-root": {
                        // borderRadius: "0px",
                        // borderTop: "1px solid #ccc",
                        p: {
                          xs: "16px 4px 16px 0px",
                          md: "16px 0px",
                          lg: "24px 0px",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          onClick={(e) => {
                            handleChange(e, 1);
                          }}
                          sx={{ mr: { xs: 1, sm: 1, md: 1 } }}
                        >
                          <Icon
                            icon="material-symbols:folder-open-outline"
                            style={{ color: "black" }}
                          />
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            loading ? handleStop() : handleSendMessage()
                          }
                          onFocus={() => setColorFocus(false)}
                          type="submit"
                          sx={{
                            ml: { xs: 1, sm: 1, md: 1 },
                          }}
                        >
                          {loading ? (
                            <StopIcon />
                          ) : (
                            <Send
                              sx={{
                                color: colorFocus ? "rgba(5, 87, 250, 1)" : "",
                              }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <FileUpload
                setMyFiles={setMyFiles}
                setValues={setValues}
                myFiles={myFiles}
                handleChange={handleChange}
                setTabValue={setTabValue}
                values={values}
                chatHistoryLength={chatHistory?.length}
              />
            </TabPanel>
          </Paper>
        </Box>
      </Grid>
    </Box>
  );
};

export default Landing;
