import React, { useCallback } from "react";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "../redux/reducer";
import Header from "../Components/Header";
import { Box } from "@mui/material";

const PrivateContainer = ({ children, roles, tabTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const checkAuth = useCallback(async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      if (data?.attributes) {
        dispatch(setUser(data?.attributes));
        return;
      } else {
        return navigate("/signin");
      }
    } catch {
      return navigate("/");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (tabTitle) document.title = tabTitle;
  }, [tabTitle]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        overflow: "hidden",
        position: "relative",
        background: "#FDFDFD",
      }}
    >
      <Header menu={user ? false : true} />
      {children}
    </Box>
  );
};

export default PrivateContainer;
