import { useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const PublicContainer = ({ children, isSingup, tabTitle }) => {
  const navigate = useNavigate();
  const checkAuth = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser();
      navigate("/landing");
    } catch (error) {
      // console.log(error);
    }
  }, [navigate]);

  useEffect(() => {
    if (!isSingup) {
      checkAuth();
    }
  });

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  return <Box>{children}</Box>;
};

export default PublicContainer;
