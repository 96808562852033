import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#0557FA", // Your primary color
    },
    secondary: {
      main: "rgba(5, 87, 250, 1)",
    },
    error: {
      main: "#ff0000",
    },
    grey: {
      gray_100: "rgba(0, 0, 0, 0.03)",
      gray_200_: "#eeeeee",
      gray_500: "rgba(0, 0, 0, 0.65)",
    },
    // Add more customizations to the palette if needed
  },
  typography: {
    h1: {
      fontSize: "2.5rem", // 40px
      fontWeight: "bold",
      "@media (max-width: 600px)": {
        fontSize: "2rem", // 32px
      },
    },
    h2: {
      fontSize: "2rem", // 32px
      fontWeight: "bold",
      "@media (max-width: 600px)": {
        fontSize: "1.75rem", // 28px
      },
    },
    h3: {
      fontSize: "1.75rem", // 28px
      fontWeight: "bold",
      "@media (max-width: 600px)": {
        fontSize: "1.5rem", // 24px
      },
    },
    h4: {
      fontSize: "1.5rem", // 24px
      fontWeight: "bold",
      "@media (max-width: 600px)": {
        fontSize: "1.25rem", // 20px
      },
    },
    h5: {
      fontSize: "1.25rem", // 20px
      fontWeight: "medium",
      "@media (max-width: 600px)": {
        fontSize: "1.125rem", // 18px
      },
    },
    h6: {
      fontSize: "1.125rem", // 18px
      fontWeight: "medium",
      "@media (max-width: 600px)": {
        fontSize: "1rem", // 16px
      },
    },
    body1: {
      fontSize: "1rem", // 16px
      fontWeight: "regular",
      // "@media (max-width: 600px)": {
      //   fontSize: "0.875rem", // 14px
      // },
    },
    body2: {
      fontSize: "0.875rem", // 14px
      fontWeight: "regular",
      "@media (max-width: 600px)": {
        fontSize: "0.75rem", // 12px
      },
    },
  },
  // Add more customizations to the theme as desired
});

export default theme;
