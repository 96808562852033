import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";

import PrivateContainer from "./router/PrivateContainer";
import PublicContainer from "./router/PublicContainer";
import { ROUTES } from "./router";
import awsExports from "../src/aws-exports";

Amplify.configure(awsExports);

function App() {
  return (
    <>
      <Router>
        <Routes>
          {ROUTES.map(({ Component, isPrivate, path, roles, tabTitle }) => (
            <Route
              exact
              path={path}
              key={path}
              element={
                isPrivate ? (
                  <PrivateContainer tabTitle={tabTitle} roles={roles}>
                    <Component />
                  </PrivateContainer>
                ) : (
                  <PublicContainer tabTitle={tabTitle}>
                    <Component />
                  </PublicContainer>
                )
              }
            />
          ))}
        </Routes>
      </Router>
    </>
  );
}

export default App;
