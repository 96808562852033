import ForgotPswd from "../Components/Auth/ForgotPswd";
import SignIn from "../Components/Auth/SignIn";
import SignUp from "../Components/Auth/SignUp";
import Verification from "../Components/Auth/Verification";
import Landing from "../pages/Landing";
import UserHistory from "../pages/UserHistory";
import Categorization from "../pages/Categorization";

export const ROUTES = [
  {
    path: "/",
    isPrivate: false,
    Component: SignIn,
    tabTitle: "praxis ai",
  },
  {
    path: "/signin",
    isPrivate: false,
    Component: SignIn,
    tabTitle: "praxis ai",
  },
  {
    path: "/signup",
    isPrivate: false,
    Component: SignUp,
    tabTitle: "praxis ai",
  },
  {
    path: "/user-history",
    isPrivate: true,
    Component: UserHistory,
    tabTitle: "praxis ai",
  },
  {
    path: "/verification",
    isPrivate: false,
    Component: Verification,
    tabTitle: "praxis ai",
  },
  {
    path: "/forgot-password",
    isPrivate: false,
    Component: ForgotPswd,
    tabTitle: "praxis ai",
  },
  {
    path: "/landing",
    isPrivate: true,
    Component: Landing,
    tabTitle: "praxis ai",
  },
  {
    path: "/categorization",
    isPrivate: true,
    Component: Categorization,
    tabTitle: "praxis ai",
  },
];
